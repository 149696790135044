import { useTranslation } from "react-i18next";
import Link from "next/link";

import { FAQ_URL } from "utils";
import * as styles from "./index.styles";

export default function HelpButton() {
  const { t } = useTranslation(["layout"]);

  return (
    <Link href={FAQ_URL} css={styles.helpLink}>
      {t("layout:faq")}
    </Link>
  );
}
