import { useTranslation } from "react-i18next";
import Button from "@comunidadfeliz/button";
import * as ConfirmationModal from "@comunidadfeliz/confirmation-modal";
import toast from "@comunidadfeliz/snackbar";

import { ConfirmationModalProps } from "types";
import { useDeleteAutomaticPaymentReminderMutation } from "api/cards/enhancedOperations";
import { getErrorMessage } from "utils";
import LinkButton from "@comunidadfeliz/link-button";
import { useEffect } from "react";

export default function UnsubscribeCardModal({
  open,
  onOpenChange,
  cardId,
  propertyId,
}: ConfirmationModalProps & { cardId: string; propertyId: string }) {
  const { t } = useTranslation(["common", "automaticPayment"]);
  const [deleteRemainder, { isLoading }] =
    useDeleteAutomaticPaymentReminderMutation();

  const handleDeleteRemainder = async () => {
    await deleteRemainder({
      input: {
        cardId,
        propertyId,
      },
    })
      .unwrap()
      .then(({ deleteAutomaticPaymentReminder }) => {
        toast({
          type: "info",
          message: deleteAutomaticPaymentReminder?.message || "",
        });
        onOpenChange(false);
      })
      .catch((e) => {
        toast({ type: "error", message: getErrorMessage(e) });
      });
  };

  useEffect(() => {
    if (isLoading) {
      const storedCards = localStorage.getItem("cards");
      localStorage.setItem("cards", (Number(storedCards) - 1).toString());
    }
  }, [isLoading]);

  return (
    <ConfirmationModal.Root open={open} onOpenChange={onOpenChange}>
      <ConfirmationModal.Content>
        <ConfirmationModal.Title>
          {t("automaticPayment:unsubscribeModal.title")}
        </ConfirmationModal.Title>
        <ConfirmationModal.Description>
          {t("automaticPayment:unsubscribeModal.description")}
        </ConfirmationModal.Description>
        <ConfirmationModal.Actions>
          <Button
            variant="error"
            onClick={handleDeleteRemainder}
            disabled={isLoading}
          >
            <p>{t("automaticPayment:unsubscribe")}</p>
          </Button>
          <LinkButton onClick={() => onOpenChange(false)} disabled={isLoading}>
            <p>{t("common:cancel")}</p>
          </LinkButton>
        </ConfirmationModal.Actions>
      </ConfirmationModal.Content>
    </ConfirmationModal.Root>
  );
}
