import { useTranslation } from "react-i18next";

import { FooterLogoCF } from "assets";
import * as styles from "./index.styles";

type FooterProps = {
  alignRight?: boolean;
};
export default function FooterCF({ alignRight }: FooterProps) {
  const { t } = useTranslation(["common"]);
  return (
    <footer css={[styles.footer(alignRight)]}>
      <span>{t("common:developedBy")}</span>
      <FooterLogoCF />
    </footer>
  );
}
