/* eslint-disable @typescript-eslint/no-var-requires */
import Image from "next/image";
import { useTranslation } from "react-i18next";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";

import { outlineLoginButton } from "styles";
import googleIcon from "assets/google.png";
import Button from "@comunidadfeliz/button";
import { OauthProviderEnum } from "types";
import getConfig from "next/config";
import useRole from "hooks/useRole";
import { useSignInOrSignUpUserOauth } from "./useGoogleLogin";

const { publicRuntimeConfig } = getConfig();
const jwt = require("jsonwebtoken");

export default function GoogleLogin() {
  const { t } = useTranslation(["layout", "owner"]);
  const { signInUserOauth } = useSignInOrSignUpUserOauth();
  const { role } = useRole();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const token = jwt.sign(
        { code: tokenResponse.access_token },
        publicRuntimeConfig.jwtSecretKey
      );
      googleLogout();
      signInUserOauth({
        input: {
          oauthToken: token,
          provider: OauthProviderEnum.Google,
          role,
        },
      });
    },
  });

  return (
    <Button css={outlineLoginButton} variant="outlined" onClick={() => login()}>
      <Image src={googleIcon} alt="image" />
      <p>{t("owner:commons.loginWithGoogle")}</p>
    </Button>
  );
}
