import { api as generatedApi } from "./operations.generated";

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ["BankData", "UserProfile"],
  endpoints: {
    UpdateProfile: { invalidatesTags: ["UserProfile"] },
    UpdateUserPayee: { invalidatesTags: ["BankData"] },
    UserBankData: { providesTags: ["BankData"] },
    UserBankDataForm: { providesTags: ["BankData"] },
    UserProfile: { providesTags: ["UserProfile"] },
  },
});

export const {
  useUpdateProfileMutation,
  useUpdateUserPayeeMutation,
  useUserBankDataFormQuery,
  useUserBankDataQuery,
  useUserProfileQuery,
} = api;
