import { useRouter } from "next/router";

import { StepPathEnum } from "utils";
import { useDispatch } from "react-redux";
import { setStep, setUtmData, setLastUtm } from "features/utm/index.slice";
import { useEffect, useRef } from "react";
import { useCreateUtmLogMutation } from "api/utm/operations.generated";
import { StepEnum } from "types";
import { useAppSelector } from "store";

const stepMap = {
  "step-1": StepEnum.Register,
  "step-2": StepEnum.PayeeData,
  "step-3": StepEnum.PropertyData,
  "step-4": StepEnum.PaymentMethod,
  successful_payment: StepEnum.Paid,
};

export default function useUtmLog() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { utm } = useAppSelector((state) => state);
  const [createLogMutation] = useCreateUtmLogMutation();
  const { payerId } = useAppSelector((state) => state.form);
  const stepRef = useRef(false);
  const successfulPaymentRef = useRef(false);

  const storeUtm = () => {
    dispatch(
      setLastUtm({
        utm_source: utm.utm_source,
        utm_medium: utm.utm_medium,
        utm_campaign: utm.utm_campaign,
        utm_id: utm.utm_id,
      })
    );
  };

  const createLog = () => {
    createLogMutation({
      input: {
        campaign: utm.utm_campaign,
        medium: utm.utm_medium,
        payerId,
        paymentId: utm.paymentId,
        source: utm.utm_source,
        entryAt: new Date(),
        lastStep: utm.step as StepEnum,
      },
    });
  };

  const cleanLastUtm = () => {
    dispatch(
      setLastUtm({
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_id: "",
      })
    );
  };

  const cleanUtmData = () => {
    dispatch(
      setUtmData({
        utm_campaign: "",
        utm_medium: "",
        utm_source: "",
        utm_id: "",
      })
    );
  };

  useEffect(() => {
    window.addEventListener("beforeunload", cleanUtmData);
    return () => {
      window.removeEventListener("beforeunload", cleanUtmData);
    };
  }, []);

  useEffect(() => {
    if (router.query.utm_source) {
      dispatch(setUtmData(router.query as any));
    }
    if (router.query.step) {
      dispatch(setStep(stepMap[router.query.step as keyof typeof stepMap]));
    }
    if (router.route === "/successful_payment") {
      dispatch(
        setStep(stepMap[router.route.split("/")[1] as keyof typeof stepMap])
      );
    }
    if (router.route.includes("registro")) {
      dispatch(setStep(stepMap["registro" as keyof typeof stepMap]));
    }
  }, [router]);

  useEffect(() => {
    if (!stepRef.current && router.query.utm_source) {
      createLogMutation({
        input: {
          campaign: (router.query.utm_campaign as string) ?? "",
          medium: (router.query.utm_medium as string) ?? "",
          payerId,
          paymentId: utm.paymentId,
          source: (router.query.utm_source as string) ?? "",
          entryAt: new Date(),
          lastStep: stepMap[router.query.step as keyof typeof stepMap],
        },
      });
      stepRef.current = true;
    }
    if (
      !stepRef.current &&
      router.query.step === StepPathEnum.STEP_1 &&
      !router.query.utm_source
    ) {
      createLogMutation({
        input: {
          campaign: "",
          medium: "",
          payerId,
          paymentId: utm.paymentId,
          source: "",
          entryAt: new Date(),
          lastStep: StepEnum.Visit,
        },
      });
    }
    if (
      router.route === "/successful_payment" &&
      !successfulPaymentRef.current
    ) {
      createLogMutation({
        input: {
          source: utm.lastUtm?.utm_source as string,
          medium: utm.lastUtm?.utm_medium as string,
          campaign: utm.lastUtm?.utm_campaign as string,
          payerId,
          paymentId: utm.paymentId,
          entryAt: new Date(),
          lastStep: StepEnum.Paid,
        },
      });
      successfulPaymentRef.current = true;
    }
    if (utm.lastUtm?.utm_campaign) {
      dispatch(setUtmData(utm.lastUtm));
      createLogMutation({
        input: {
          campaign: (utm.lastUtm.utm_campaign as string) ?? "",
          medium: (utm.lastUtm.utm_medium as string) ?? "",
          payerId,
          paymentId: utm.paymentId,
          source: (utm.lastUtm.utm_source as string) ?? "",
          entryAt: new Date(),
          lastStep: stepMap[router.query.step as keyof typeof stepMap],
        },
      });
      cleanLastUtm();
    }
  }, [router]);

  return { storeUtm, createLog };
}
