import { theme } from "@comunidadfeliz/theme";
import { css } from "@emotion/react";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "styles/breakpoints";

export const linkButton = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  display: flex;
  gap: 3px;
  align-items: center;
  color: ${theme.colors.gray.primary};
  &:hover {
    color: var(--color-links) !important;
    text-decoration: underline;
  }
`;

export const button = css`
  background-color: var(--color-buttons) !important;
  :hover {
    background-color: var(--color-buttons-hover) !important;
  }
  &:active {
    background-color: var(--color-buttons-active) !important;
  }
  &[disabled] {
    background-color: ${theme.colors.gray.quaternary} !important;
    color: ${theme.colors.white} !important;
    cursor: not-allowed;
  }
`;

export const formButton = css`
  ${button};
  align-self: center;
  width: min(100%, 225px);
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    max-width: auto;
    width: 100%;
  }
`;

export const outlineButton = css`
  border-color: var(--color-buttons) !important;
  min-width: 220px;
  width: fit-content;
  text-wrap: nowrap;
  &:disabled {
    background-color: ${theme.colors.gray.quaternary} !important;
    border-color: ${theme.colors.gray.quaternary} !important;
    color: ${theme.colors.white} !important;
    user-select: none;
    pointer-events: none;
  }
  &:hover {
    background-color: var(--color-buttons-hover) !important;
  }
  &:active {
    background-color: var(--color-buttons-active) !important;
  }
`;
export const goHome = css`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.colors.gray.primary};
  cursor: pointer;
  white-space: nowrap;
  height: 30px;
  &:hover {
    color: var(--color-links);
    > svg > path {
      stroke: var(--color-links);
    }
  }
  & > svg {
    stroke: ${theme.colors.gray.primary};
    width: 15px;
    height: 19.33px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    justify-content: flex-end;
  }
`;

export const goBack = css`
  ${goHome};
  & > svg {
    transform: rotate(-90deg);
  }
`;

export const goNext = css`
  ${goHome};
  & > svg {
    transform: rotate(90deg);
  }
`;

export const whiteButton = css`
  background-color: ${theme.colors.white} !important;
  color: ${theme.colors.gray.primary} !important;
  box-shadow: none !important;
  :hover {
    background-color: var(--color-buttons-hover) !important;
  }
  &:active {
    background-color: var(--color-buttons-active) !important;
  }
  &[disabled] {
    background-color: ${theme.colors.gray.quaternary} !important;
    color: ${theme.colors.white} !important;
    cursor: not-allowed;
  }
`;

export const outlineLoginButton = css`
  border-color: ${theme.colors.gray.quaternary};
  width: 100%;
  height: 38px;
  justify-content: center;
  display: flex;
  gap: 5px;
  align-items: center;
  margin: auto;
  &:disabled {
    background-color: ${theme.colors.gray.quaternary} !important;
    border-color: ${theme.colors.gray.quaternary} !important;
    color: ${theme.colors.white} !important;
    user-select: none;
    pointer-events: none;
  }
  &:active {
    background-color: var(--color-blue-light) !important;
  }
`;

export const iconButton = css`
  &:hover {
    background-color: var(--color-blue-light) !important;
  }
`;

export const optionsButton = css`
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3px;
`;

export const deleteOption = css`
  color: ${theme.colors.red.primary};
  &:hover {
    background-color: ${theme.colors.red.quaternary};
  }
`;

export const dropdownOptions = css`
  min-width: fit-content;
  padding: 12px 0;
  & > * {
    white-space: pre;
    padding: 16px 17px;
  }
`;

export const formButtonContainer = (multi = true) => css`
  display: flex;
  align-items: center;
  justify-content: ${multi ? "space-between" : "center"};
  gap: 20px;
  width: 100%;
  padding: 20px 0;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column-reverse;
  }
`;

export const registerButtonStyle = css`
  height: 38px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding: 0 10px;
  cursor: pointer;
  color: ${theme.colors.gray.primary};
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed ${theme.colors.gray.secondary};
  background: #fff;
  @media (max-width: ${TABLET_BREAKPOINT}) {
    width: 100%;
  }
`;

export const dashedButton = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding: 0 10px;
  cursor: pointer;
  color: ${theme.colors.gray.primary};
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed ${theme.colors.gray.secondary};
  background: #fff;
  @media (max-width: ${TABLET_BREAKPOINT}) {
    width: 100%;
  }
`;
