/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import toast from "@comunidadfeliz/snackbar";
import { useRouter } from "next/router";
import { googleLogout } from "@react-oauth/google";

import { useAppDispatch } from "store";
import {
  useSignInUserMutation,
  useSignOutUserMutation,
} from "api/owner/auth/operations.generated";
import { ROUTES, getErrorMessage } from "utils";
import { clearState } from "features/lessee/onboarding/index.slice";
import { useLazyPayerPropertiesQuery } from "api/lessee/properties/operations.generated";
import useRole from "hooks/useRole";
import { UserRoleEnum } from "types";
import { login, logout } from "../index.slice";

export function useOwnerLogin() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [signInUser, { data, isSuccess, error }] = useSignInUserMutation();
  const [payerPropertiesQuery] = useLazyPayerPropertiesQuery();
  const { isRole } = useRole();

  useEffect(() => {
    if (error) {
      toast({ type: "error", message: getErrorMessage(error) });
    }
    if (isSuccess && data && data.signInUser) {
      const { credentials } = data.signInUser.data;

      if (credentials) {
        dispatch(clearState());
        dispatch(login(credentials));

        if (isRole.owner || data.signInUser.data.role === UserRoleEnum.Payee) {
          router.push(ROUTES.OWNER.PROPERTIES);
        }
        if (isRole.payer) {
          payerPropertiesQuery()
            .unwrap()
            .then(({ payerProperties }) => {
              if (payerProperties?.totalCount) {
                router.push(ROUTES.PAYER.PROPERTIES);
              } else {
                router.push(ROUTES.PAYER.REGISTER_PROPERTY);
              }
            });
        }
      }
    }
  }, [isSuccess, error]);

  return { signInUser };
}

export function useOwnerSignOutUser() {
  const dispatch = useAppDispatch();
  const [signOutUser, { isSuccess, error }] = useSignOutUserMutation();

  useEffect(() => {
    if (error) {
      toast({ type: "error", message: getErrorMessage(error) });
    }
    if (isSuccess) {
      dispatch(logout());
      dispatch(clearState());
      googleLogout();
    }
  }, [isSuccess, error]);

  return { signOutUser };
}
