import { useTranslation } from "react-i18next";
import Button from "@comunidadfeliz/button";
import * as Dropdown from "@comunidadfeliz/dropdown";

import { ArrowIcon, UserIcon } from "assets";
import { useOwnerSignOutUser } from "features/owner/login";
import useAuth from "hooks/useAuth";
import { whiteButton } from "styles";
import useRole from "hooks/useRole";
import useUserProfile from "api/useUserProfile";
import * as styles from "./index.styles";

export default function AccountMenu() {
  const { t } = useTranslation(["layout", "owner", "common"]);
  const {
    isAuthenticated,
    redirect,
    payerRedirect,
    routesWithoutLoginButton,
    asPath,
    isOwner,
    redirectToLogin,
  } = useAuth();

  const { data } = useUserProfile();
  const { signOutUser } = useOwnerSignOutUser();
  const { userRedirect } = useRole();

  const signOut = () => signOutUser();

  const isRouteWithoutLoginButton = routesWithoutLoginButton.some((route) =>
    asPath.includes(route)
  );

  const profileRedirect = isOwner ? redirect.profile : payerRedirect.profile;

  if (!isAuthenticated && !isRouteWithoutLoginButton)
    return (
      <Button css={whiteButton} onClick={redirectToLogin}>
        {t("owner:login.login")}
      </Button>
    );

  return !isRouteWithoutLoginButton ? (
    <Dropdown.Root>
      <Dropdown.Trigger css={styles.accountMenuButton}>
        <UserIcon />
        <h1>{`Hola ${data?.userProfile?.name}`}</h1>
        <ArrowIcon className="arrow" />
      </Dropdown.Trigger>
      <Dropdown.Content css={styles.acountMenu}>
        <Dropdown.Item onClick={profileRedirect}>
          {t("layout:navBar.accountMenu.myAccount")}
        </Dropdown.Item>
        {isOwner && (
          <Dropdown.Item onClick={redirect.myBankData}>
            {t("layout:navBar.accountMenu.bankData")}
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={userRedirect[isOwner ? "payer" : "owner"]}>
          {isOwner
            ? t("common:goToLesseeProfile")
            : t("common:goToOwnerProfile")}
        </Dropdown.Item>
        <Dropdown.Item onClick={signOut}>
          {t("layout:navBar.accountMenu.logOut")}
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  ) : null;
}
