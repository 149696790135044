import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

import GoBackButton from "features/form/components/GoBackButton";
import { useCategoryKeyPath } from "hooks";
import { CategoryEnum } from "types";
import { StepPathEnum } from "utils";
import * as styles from "./index.styles";

export default function Header() {
  const { t } = useTranslation(["layout"]);

  const categoryPath = useCategoryKeyPath();
  const router = useRouter();

  const categoryTitle = {
    [CategoryEnum.Lease]: t("layout:pay", { context: CategoryEnum.Lease }),
    [CategoryEnum.CommonExpense]: t("layout:pay", { context: categoryPath }),
  };

  const isStep1 = Boolean(router.asPath.match(StepPathEnum.STEP_1));

  const showGoBackButton = !isStep1;

  return (
    <section css={styles.headerSection}>
      <header css={styles.header}>
        <h1 css={styles.title}>{categoryTitle[categoryPath]}</h1>
      </header>
      {showGoBackButton && <GoBackButton />}
    </section>
  );
}
