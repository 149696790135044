import * as styles from "styles";
import * as Select from "@comunidadfeliz/select-input";

import { useTranslation } from "react-i18next";
import { EventType, InputKeys } from "types";

export type TSelectInputFilters = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  onChange?: (event: EventType) => void;
  name: InputKeys;
  value: string;
  disabled?: boolean;
};

export default function SelectInput({
  options,
  onChange,
  name,
  value,
  disabled = false,
}: TSelectInputFilters) {
  const { t } = useTranslation(["constants", "common", "constants"]);

  const label = t(`owner:onboarding.${name}.label`, { defaultValue: "" });

  return (
    <div css={styles.selectFilter}>
      <Select.Root>
        {label && <Select.Label>{label}</Select.Label>}
        <Select.Input
          value={value}
          placeholder={label}
          name={name}
          disabled={disabled}
        >
          {options?.map((option) => (
            <Select.Option
              key={option.key ?? option}
              css={styles.selectOption}
              onClick={() => {
                onChange?.({
                  target: { name, value: option.key ?? option },
                } as EventType);
              }}
            >
              {t(`constants:accountTypes.${option}`, {
                defaultValue: (option.label as string) ?? option,
              })}
            </Select.Option>
          ))}
        </Select.Input>
      </Select.Root>
    </div>
  );
}
