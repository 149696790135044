import * as Types from '../../../types/schema';

import { api } from 'store/baseApi';

/* eslint-disable */
/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit one of the `.graphql` files in this project and run
 * yarn codegen
 * for this file to be re-created
 * */

export type PayerPropertiesQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.PropertiesTableFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type PayerPropertiesQuery = { __typename?: 'Query', payerProperties?: { __typename?: 'PayerPropertyTableConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges?: Array<{ __typename?: 'PayerPropertyTableEdge', node?: { __typename?: 'PayerPropertyTable', nickname?: string | null, communityAddress?: string | null, name?: string | null, id: string, currency?: Types.CurrencyEnum | null, convertedAmountStr?: string | null, convertedAmount?: number | null, amount?: number | null, amountStr?: string | null, recurringInvoiceActive: boolean, payeeId?: string | null, payerId?: string | null, recurringInvoiceDayIndicator?: number | null, payee?: { __typename?: 'Payee', bank: Types.BankEnum, bankStr: string, bankAccountType?: Types.BankAccountTypeEnum | null, bankAccountNumber?: string | null, email: string, name: string, tin: string, id: string } | null, balance?: { __typename?: 'Balance', lastTransaction?: { __typename?: 'BusinessTransaction', amount: number, payment?: { __typename?: 'Payment', id: string, amountStr: string, attemptStatus: Types.AttemptStatusEnum, date: any } | null, invoice?: { __typename?: 'Invoice', currency: Types.CurrencyEnum, amount: number, amountStr: string, paidAt?: any | null, issuedAt: any } | null } | null } | null } | null } | null> | null } | null };

export type PropertyDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type PropertyDetailsQuery = { __typename?: 'Query', property?: { __typename?: 'Property', id: string, name: string, communityAddress: string, currency?: Types.CurrencyEnum | null, amount?: number | null, amountStr?: string | null, payee?: { __typename?: 'Payee', id: string, name: string, email: string, bank: Types.BankEnum, bankStr: string, bankAccountType?: Types.BankAccountTypeEnum | null, bankAccountNumber?: string | null, tin: string } | null, payer?: { __typename?: 'Payer', id: string, name?: string | null, email: string } | null } | null };

export type DeletePropertyMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeletePropertyMutation = { __typename?: 'Mutation', deleteProperty?: { __typename?: 'DeletePropertyPayload', message: string, success: boolean } | null };


export const PayerPropertiesDocument = `
    query PayerProperties($input: PropertiesTableFilterInput, $first: Int, $last: Int, $after: String, $before: String) {
  payerProperties(
    after: $after
    before: $before
    first: $first
    last: $last
    input: $input
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        nickname
        communityAddress
        name
        id
        currency
        convertedAmountStr
        convertedAmount
        amount
        amountStr
        recurringInvoiceActive
        payee {
          bank
          bankStr
          bankAccountType
          bankAccountNumber
          email
          name
          tin
          id
        }
        payeeId
        payerId
        recurringInvoiceDayIndicator
        balance {
          lastTransaction {
            amount
            payment {
              id
              amountStr
              attemptStatus
              date
            }
            invoice {
              currency
              amount
              amountStr
              paidAt
              issuedAt
            }
          }
        }
      }
    }
  }
}
    `;
export const PropertyDetailsDocument = `
    query PropertyDetails($id: ID!) {
  property(id: $id) {
    id
    name
    communityAddress
    currency
    amount
    amountStr
    payee {
      id
      name
      email
      bank
      bankStr
      bankAccountType
      bankAccountNumber
      tin
    }
    payer {
      id
      name
      email
    }
  }
}
    `;
export const DeletePropertyDocument = `
    mutation DeleteProperty($id: ID!) {
  deleteProperty(id: $id) {
    message
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    PayerProperties: build.query<PayerPropertiesQuery, PayerPropertiesQueryVariables | void>({
      query: (variables) => ({ document: PayerPropertiesDocument, variables })
    }),
    PropertyDetails: build.query<PropertyDetailsQuery, PropertyDetailsQueryVariables>({
      query: (variables) => ({ document: PropertyDetailsDocument, variables })
    }),
    DeleteProperty: build.mutation<DeletePropertyMutation, DeletePropertyMutationVariables>({
      query: (variables) => ({ document: DeletePropertyDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { usePayerPropertiesQuery, useLazyPayerPropertiesQuery, usePropertyDetailsQuery, useLazyPropertyDetailsQuery, useDeletePropertyMutation } = injectedRtkApi;

