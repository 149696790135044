import { useTranslation } from "react-i18next";
import Button from "@comunidadfeliz/button";

import { outlineLoginButton } from "styles";
import { LogoCF } from "assets";
import { OAUTH_CF_URL } from "utils";

export default function CfLogin() {
  const { t } = useTranslation(["owner"]);

  const login = () => {
    window.location.href = OAUTH_CF_URL;
  };

  return (
    <Button css={outlineLoginButton} variant="outlined" onClick={login}>
      <LogoCF width={20} />
      <p>{t("owner:commons.loginWithCf")}</p>
    </Button>
  );
}
