import { useTranslation } from "next-i18next";

import { dashedButton } from "styles";
import { useAppDispatch, useAppSelector } from "store";
import SelectInput from "features/owner/components/SelectInput";
import useUserCards from "api/useUserCards";
import LinkButton from "@comunidadfeliz/link-button";
import { cardLogo } from "utils/cards";
import { ONE_CLICK_PAYMENT_GATEWAY_ID, removeNonNumeric } from "utils";
import { Card } from "types";
import { useRouter } from "next/router";
import useEnrollCard from "api/useEnrollCard";
import * as styles from "./index.styles";
import { setCard } from "./index.slice";

export default function CardInputSelector() {
  const { t } = useTranslation(["common", "automaticPayment", "lessee"]);
  const { push } = useRouter();
  const {
    automatic_payment: { card, propertyId },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const { cards } = useUserCards({
    paymentGatewayId: ONE_CLICK_PAYMENT_GATEWAY_ID,
    propertyId: propertyId as string,
  });
  const { enrollCardLink, refetch, isFetching } = useEnrollCard({
    paymentGatewayId: ONE_CLICK_PAYMENT_GATEWAY_ID,
    propertyId: propertyId as string,
    toDashboard: true,
  });

  let date = new Date();
  const handleMouseEnter = () => {
    // refetch if the last fetch was more than 1 minute ago
    if (new Date().getTime() - date.getTime() > 60000) {
      refetch();
    }
    date = new Date();
  };

  const options = cards?.map((c) => {
    return (
      <div css={styles.cardInfoStyle} key={c.id}>
        {c && cardLogo[c?.cardType]}
        {t("lessee:cards.creditCard")}
        <div css={styles.cardNumberStyle}>
          {t("lessee:cards.hiddenDigitsCard", {
            card: removeNonNumeric(c?.cardNumber),
          })}
        </div>
      </div>
    );
  });

  const cardPlaceholder = card.id ? (
    <div css={styles.cardInfoStyleInputValue}>
      {card.id && cardLogo[card.cardType]}
      {t("lessee:cards.creditCard")}
      <div css={styles.cardNumberStyle}>
        {t("lessee:cards.hiddenDigitsCard", {
          card: removeNonNumeric(card?.cardNumber),
        })}
      </div>
    </div>
  ) : (
    <div css={styles.cardInfoStyleInputValue}>
      {t("lessee:cards.selectCard")}
    </div>
  );

  return (
    <div css={styles.registerNewCard}>
      {cards && cards.length > 0 && (
        <>
          {cardPlaceholder}
          <SelectInput
            options={options as []}
            name="card"
            value=""
            onChange={(e) => {
              dispatch(
                setCard(
                  cards?.find((option) => option.id === e.target.value) as Card
                )
              );
            }}
          />
        </>
      )}
      <LinkButton
        onClick={() => push(enrollCardLink as string)}
        css={dashedButton}
        onMouseEnter={handleMouseEnter}
        disabled={isFetching}
      >
        {t("lessee:cards.registerCard")}
      </LinkButton>
    </div>
  );
}
