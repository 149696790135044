import { css } from "@emotion/react";
import { MOBILE_BREAKPOINT } from "./breakpoints";

export const modalRoot = css`
  @media (max-width: ${MOBILE_BREAKPOINT}) {
  }
`;

export const footer = css`
  padding: 16px;
  gap: 16px;
  > * {
    width: fit-content;
    margin: 0;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    align-items: stretch;
    > button {
      width: 100%;
      &:last-of-type {
        display: none;
      }
    }
  }
`;

export const modalContent = css`
  width: 90%;
  &[data-state="open"] {
    box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.4);
  }
  > div:last-of-type {
    ${footer}
  }
`;

export const modalContainer = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 100%;
  padding: 16px;
`;
