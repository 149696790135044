/* eslint-disable no-nested-ternary */
import { ChangeEvent, InputHTMLAttributes, useEffect, useState } from "react";
import * as TextInput from "@comunidadfeliz/text-input";
import { useTranslation } from "react-i18next";

import { useDebouncedFunction } from "hooks";
import { InputKeys, ValidationType } from "types";
import PasswordButton from "./PasswordButton";

type TextInputFormProps = {
  validator?: (value: string) => boolean;
  hint?: string;
  showError?: boolean;
  name: InputKeys;
  altName?: string;
  currency?: string;
  errorMsg?: string;
  showLabel?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export default function Input({
  validator,
  value,
  onChange,
  showError = false,
  showLabel = true,
  name,
  altName,
  currency,
  errorMsg,
  ...rest
}: TextInputFormProps) {
  const { t } = useTranslation(["owner"]);
  const [isError, setIsError] = useState(false);
  const [type, setType] = useState(rest.type || "text");

  const label = t(`owner:signUp.${altName ?? name}.label`, {
    defaultValue: "",
  });
  const hint = t(`owner:signUp.${altName ?? name}.hint`, { defaultValue: "" });
  const error = t(`owner:signUp.${altName ?? name}.error`, {
    defaultValue: "",
  });

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (validator) {
      setIsError(!validator?.(event.target.value));
    }
  };
  const debouncedValidator = useDebouncedFunction(handleOnBlur, 200);

  const handleOnchange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    debouncedValidator(event);
  };

  useEffect(() => {
    if (
      (!validator && rest.type === ValidationType.PASSWORD) ||
      name === ValidationType.ALPHANUMERIC_CODE ||
      name === ValidationType.ALPHANUMERIC_CODE_EDIT
    ) {
      setIsError(showError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showError]);

  return (
    <TextInput.Root>
      {showLabel && value && <TextInput.Label>{label}</TextInput.Label>}
      <TextInput.Input
        defaultValue={value}
        placeholder={currency ?? label}
        value={value}
        onChange={handleOnchange}
        error={isError}
        onBlur={handleOnBlur}
        name={name}
        {...rest}
        type={type}
      />
      {isError && <TextInput.Hint>{errorMsg ?? error}</TextInput.Hint>}
      {hint && !isError && <TextInput.Hint>{hint}</TextInput.Hint>}
      {rest.type === "password" && <PasswordButton {...{ type, setType }} />}
    </TextInput.Root>
  );
}
