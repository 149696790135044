/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import toast from "@comunidadfeliz/snackbar";
import { useRouter } from "next/router";

import { useAppDispatch } from "store";
import { useSignInOrSignUpUserOauthMutation } from "api/owner/auth/operations.generated";
import { ROUTES, getErrorMessage } from "utils";
import { useLazyPayerPropertiesQuery } from "api/lessee/properties/operations.generated";
import useRole from "hooks/useRole";
import { login } from "../index.slice";

export function useSignInOrSignUpUserOauth() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [signInUserOauth, { data, isSuccess, error }] =
    useSignInOrSignUpUserOauthMutation();
  const [payerPropertiesQuery] = useLazyPayerPropertiesQuery();
  const { isRole } = useRole();

  useEffect(() => {
    if (error) {
      toast({ type: "error", message: getErrorMessage(error) });
    }
    if (isSuccess && data && data.signInOrSignUpUserOauth?.data) {
      const { credentials } = data.signInOrSignUpUserOauth.data;
      if (credentials) {
        dispatch(login(credentials));
        if (isRole.owner) {
          router.push(ROUTES.OWNER.PROPERTIES);
        }
        if (isRole.payer) {
          payerPropertiesQuery()
            .unwrap()
            .then(({ payerProperties }) => {
              if (payerProperties?.totalCount) {
                router.push(ROUTES.PAYER.PROPERTIES);
              } else {
                router.push(ROUTES.PAYER.REGISTER_PROPERTY);
              }
            });
          router.push(ROUTES.PAYER.REGISTER_PROPERTY);
        }
      }
    }
  }, [isSuccess, error]);

  return { signInUserOauth };
}
