import * as Types from '../../types/schema';

import { api } from 'store/baseApi';

/* eslint-disable */
/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit one of the `.graphql` files in this project and run
 * yarn codegen
 * for this file to be re-created
 * */

export type CreateUtmLogMutationVariables = Types.Exact<{
  input: Types.CreateUtmLogInput;
}>;


export type CreateUtmLogMutation = { __typename?: 'Mutation', createUtmLog?: { __typename?: 'CreateUtmLogPayload', message: string, success: boolean, data: { __typename?: 'UtmLog', id: string, entryAt: any, source?: string | null, medium?: string | null, campaign?: string | null, country?: string | null, city?: string | null, payerId?: string | null, lastStep: Types.StepEnum, paymentId?: string | null } } | null };


export const CreateUtmLogDocument = `
    mutation createUtmLog($input: CreateUtmLogInput!) {
  createUtmLog(input: $input) {
    data {
      id
      entryAt
      source
      medium
      campaign
      country
      city
      payerId
      lastStep
      paymentId
    }
    message
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createUtmLog: build.mutation<CreateUtmLogMutation, CreateUtmLogMutationVariables>({
      query: (variables) => ({ document: CreateUtmLogDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateUtmLogMutation } = injectedRtkApi;

