import { css } from "@emotion/react";
import { theme } from "@comunidadfeliz/theme";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "styles/breakpoints";

export const inputContainer = (prefix?: boolean) => css`
  display: grid;
  align-items: center;
  gap: 14px;
  grid-template-columns: 1fr;
  &::after {
    content: attr(data-prefix);
    position: absolute;
    left: 16px;
    font-weight: 500;
    font-size: 14px;
    color: ${theme.colors.gray.primary};
  }
  & > svg {
    margin: auto;
  }
  & > input[type="number"]::-webkit-inner-spin-button,
  & > input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    padding-left: ${prefix ? "32px" : "0 auto"};
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: 1fr;
    & > svg {
      display: none;
    }
  }
`;

export const button = css`
  width: 90%;
  max-width: 388px;
  margin: 12px auto 0;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-buttons);
  &:focus,
  :hover {
    background-color: var(--color-buttons-hover) !important;
  }
  &:active {
    background-color: var(--color-buttons-light-active) !important;
  }
  &[disabled] {
    background-color: ${theme.colors.gray.quaternary} !important;
    color: ${theme.colors.white} !important;
    cursor: not-allowed;
  }
`;

export const goBack = css`
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.colors.gray.primary};
  cursor: pointer;
  white-space: nowrap;
  width: 96px;
  height: 30px;
  &:hover {
    color: var(--color-links);
    > svg > path {
      stroke: var(--color-links);
    }
  }
  & > svg {
    transform: rotate(-90deg);
    stroke: ${theme.colors.gray.primary};
    width: 15px;
    height: 19.33px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    justify-content: flex-end;
  }
`;

export const helpLink = css`
  color: var(--color-buttons);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  text-align: right;
  display: block;
  @media (max-width: ${TABLET_BREAKPOINT}) {
    color: ${theme.colors.white};
  }
`;

export const checkboxContainer = css`
  padding: 0 16px;
  & button[data-state="checked"] {
    background-color: var(--color-checkbox) !important;
  }
  & button[data-state="unchecked"]:hover::before {
    background-color: var(--color-checkbox-unchecked) !important;
    opacity: 0.6 !important;
  }
`;

export const termsAndConditions = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const link = css`
  color: var(--color-links);
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
`;

export const secureSite = css`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.green.tertiary};
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0px 1px 2px rgba(196, 198, 207, 0.5);
  margin-top: 12px;
  & > h1 {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: ${theme.colors.green.primary};
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      font-size: 12px;
      justify-content: center;
    }
  }
  & > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: ${theme.colors.gray.primary};
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      display: none;
    }
  }
`;

export const errorCard = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background: ${theme.colors.white};
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0px 1px 2px rgba(196, 198, 207, 0.5);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export const errorTitle = css`
  color: ${theme.colors.gray.primary};
  font-weight: 700;
  margin: auto;
`;

export const errorSubtitle = css`
  margin: auto;
  color: ${theme.colors.gray.secondary};
  font-weight: 500;
`;

export const appBanner = css`
  margin-top: 12px;
  cursor: pointer;
  border-radius: 4px;
  border: 4px solid ${theme.colors.green.primary};
  background: ${theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(196, 198, 207, 0.5);
  padding: 15px 38px;
  color: #232942;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
  @media (max-width: ${TABLET_BREAKPOINT}) {
    padding: 16px 12px;
    border-width: 2px;
    justify-content: center;
    * {
      text-align: center;
    }
    h3 {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.25px;
    }
    svg {
      display: none;
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: -12px;
    margin-bottom: -24px;
  }
`;
