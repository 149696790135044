import { useEnrollCardLinkQuery } from "api/cards/operations.generated";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ONE_CLICK_PAYMENT_GATEWAY_ID } from "utils";

export default function useEnrollCard({
  paymentGatewayId,
  propertyId,
  toDashboard,
}: {
  paymentGatewayId: string;
  propertyId: string;
  toDashboard?: boolean;
}) {
  const { data, ...rest } = useEnrollCardLinkQuery(
    paymentGatewayId === ONE_CLICK_PAYMENT_GATEWAY_ID
      ? {
          input: {
            callback: `${window.location.origin}/arrendatario/create_card?Id=${paymentGatewayId}&p=${propertyId}&toDashboard=${toDashboard}`,
            paymentGatewayId,
          },
        }
      : skipToken,
    { refetchOnFocus: false }
  );
  return { enrollCardLink: data?.enrollCardLink, ...rest };
}
