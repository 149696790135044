import { Trans, useTranslation } from "next-i18next";
import Button from "@comunidadfeliz/button";
import * as Modal from "@comunidadfeliz/modal";
import toast from "@comunidadfeliz/snackbar";

import { button, modalContainer, modalContent } from "styles";
import {
  DEFAULT_LOCALE,
  getErrorMessage,
  removeNonNumeric,
  validateGraphqlMutationResponse,
} from "utils";
import {
  useCreateAutomaticPaymentReminderMutation,
  useDeleteAutomaticPaymentReminderMutation,
} from "api/cards/enhancedOperations";
import { useAppDispatch, useAppSelector } from "store";
import SelectInput from "features/owner/components/SelectInput";
import { cardLogo } from "utils/cards";
import { InfoIcon } from "@comunidadfeliz/icons";
import { DateTime } from "luxon";
import { useCalculateAutomaticPaymentDatesQuery } from "api/cards/operations.generated";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useRouter } from "next/router";
import { Card } from "types";
import { setDayNumber } from "./index.slice";
import * as styles from "./index.styles";
import CardInputSelector from "./CardInputSelector";

type TEditProfileModalProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  subscribedCard?: Card;
  showSelectCardInput?: boolean;
};

export default function SubscribeAutomaticPaymentModal({
  isOpen,
  onOpenChange,
  subscribedCard,
  showSelectCardInput = false,
}: TEditProfileModalProps) {
  const { t } = useTranslation(["common", "automaticPayment", "lessee"]);
  const dispatch = useAppDispatch();
  const {
    automatic_payment: { propertyId, dayNumber, force, card },
  } = useAppSelector((state) => state);
  const { query } = useRouter();
  const [DeleteAutomaticPaymentReminder] =
    useDeleteAutomaticPaymentReminderMutation();
  const [createAutomaticPaymentReminder, { isLoading }] =
    useCreateAutomaticPaymentReminderMutation();
  const { data: calculateDayData } = useCalculateAutomaticPaymentDatesQuery(
    isOpen && dayNumber
      ? {
          paymentDayIndex: Number(dayNumber),
        }
      : skipToken
  );

  const options = [
    t("automaticPayment:modal.lastBusinessDay"),
    ...Array.from({ length: 28 }, (_, i) =>
      t("automaticPayment:modal.day", { day: i + 1 })
    ),
  ];
  const lastDayNumber = "31";

  const firstChargeDate = DateTime.fromFormat(
    calculateDayData?.calculateAutomaticPaymentDates.firstChargeDate ?? "",
    "yyyy-MM-dd"
  );

  const firstPayDate = DateTime.fromFormat(
    calculateDayData?.calculateAutomaticPaymentDates.firstPayDate ?? "",
    "yyyy-MM-dd"
  );

  const handleCreateAutomaticPaymentReminder = async () => {
    try {
      if (subscribedCard) {
        const deleteCardResponse = await DeleteAutomaticPaymentReminder({
          input: {
            cardId: subscribedCard?.id as string,
            propertyId: propertyId || (query?.p as string),
          },
        }).unwrap();

        const { success } = validateGraphqlMutationResponse(
          deleteCardResponse.deleteAutomaticPaymentReminder,
          t("common:genericError")
        );

        if (!success) throw new Error(t("common:genericError"));
      }

      const input = {
        propertyId: propertyId || (query?.p as string),
        cardId: card.id,
        dayNumber: Number(dayNumber),
        payerChargeDate: firstChargeDate.toISODate(),
        force,
      };
      const response = await createAutomaticPaymentReminder({ input }).unwrap();
      const { data, message } = validateGraphqlMutationResponse(
        response.createAutomaticPaymentReminder,
        t("common:genericError")
      );

      if (!data) throw new Error(t("common:genericError"));

      toast({ type: "info", message });
      onOpenChange(false);
    } catch (error) {
      toast({ type: "error", message: getErrorMessage(error) });
    }
  };

  const handleChange = (value: string) => {
    if (value === options[0]) {
      dispatch(setDayNumber(lastDayNumber));
      return;
    }
    dispatch(setDayNumber(options.indexOf(value).toString()));
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onOpenChange} modal={false}>
      <Modal.Content css={modalContent}>
        <Modal.Header>{t("automaticPayment:modal.title")}</Modal.Header>
        <Modal.Description css={modalContainer}>
          <Trans>
            <div css={styles.modalContent}>
              {!subscribedCard?.id && showSelectCardInput ? (
                <CardInputSelector />
              ) : (
                <div css={styles.cardInfoStyle}>
                  {card && cardLogo[card?.cardType]}
                  {t("lessee:cards.creditCard")}
                  <div css={styles.cardNumberStyle}>
                    {t("lessee:cards.hiddenDigitsCard", {
                      card: removeNonNumeric(card?.cardNumber),
                    })}
                  </div>
                </div>
              )}
              <div css={styles.selectDay}>
                <h2>{t("automaticPayment:modal.selectDay")}</h2>
                <SelectInput
                  options={options}
                  name="chargeDay"
                  value={
                    dayNumber === lastDayNumber
                      ? (options[0] as string)
                      : t("automaticPayment:modal.day", { day: dayNumber })
                  }
                  onChange={(e) => handleChange(e.target.value)}
                />
              </div>
              <p>
                {t("automaticPayment:modal.description", {
                  day: firstPayDate
                    .setLocale(DEFAULT_LOCALE)
                    .toFormat("d 'de' MMMM"),
                  date: firstChargeDate.toLocaleString(DateTime.DATE_FULL),
                })}
              </p>
              <ul css={styles.disclaimer}>
                <span>
                  <InfoIcon />
                  <p>{t("automaticPayment:modal.disclaimer.title")}</p>
                </span>
                <li>{t("automaticPayment:modal.disclaimer.notify")}</li>
                <li>{t("automaticPayment:modal.disclaimer.holiday")}</li>
              </ul>
            </div>
          </Trans>
        </Modal.Description>
        <Modal.Footer>
          <Button
            css={button}
            onClick={handleCreateAutomaticPaymentReminder}
            disabled={isLoading || !card.id}
          >
            {t("common:subscribe")}
          </Button>
          <Button variant="link" onClick={() => onOpenChange(false)}>
            {t("common:cancel")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
}
