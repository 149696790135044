import * as Types from '../../../types/schema';

import { api } from 'store/baseApi';

/* eslint-disable */
/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit one of the `.graphql` files in this project and run
 * yarn codegen
 * for this file to be re-created
 * */

export type SignInUserMutationVariables = Types.Exact<{
  input: Types.SignInUserInput;
}>;


export type SignInUserMutation = { __typename?: 'Mutation', signInUser?: { __typename?: 'SignInUserPayload', message: string, success: boolean, data: { __typename?: 'User', id: string, role: Types.UserRoleEnum, credentials?: { __typename?: 'Credential', accessToken: string, client: string, expiry: number, tokenType: string, uid: string } | null } } | null };

export type SignOutUserMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SignOutUserMutation = { __typename?: 'Mutation', signOutUser?: { __typename?: 'SignOutUserPayload', message: string, success: boolean, data: { __typename?: 'User', id: string, credentials?: { __typename?: 'Credential', accessToken: string, client: string, expiry: number, tokenType: string, uid: string } | null } } | null };

export type SignUpUserMutationVariables = Types.Exact<{
  input: Types.SignUpUserInput;
}>;


export type SignUpUserMutation = { __typename?: 'Mutation', signUpUser?: { __typename?: 'SignUpUserPayload', message: string, success: boolean, data: { __typename?: 'User', email: string, id: string, role: Types.UserRoleEnum, credentials?: { __typename?: 'Credential', accessToken: string, client: string, expiry: number, tokenType: string, uid: string } | null } } | null };

export type SignInOrSignUpUserOauthMutationVariables = Types.Exact<{
  input: Types.SignInOrSignUpUserOauthInput;
}>;


export type SignInOrSignUpUserOauthMutation = { __typename?: 'Mutation', signInOrSignUpUserOauth?: { __typename?: 'SignInOrSignUpUserOauthPayload', message: string, success: boolean, data: { __typename?: 'User', id: string, role: Types.UserRoleEnum, credentials?: { __typename?: 'Credential', accessToken: string, client: string, expiry: number, tokenType: string, uid: string } | null } } | null };

export type CfAuthMutationVariables = Types.Exact<{
  cfAuthToken: Types.Scalars['String'];
}>;


export type CfAuthMutation = { __typename?: 'Mutation', cfAuth?: { __typename?: 'CfAuthPayload', message: string, success: boolean, data: { __typename?: 'CfAuth', redirectPath: string, user?: { __typename?: 'User', id: string, email: string, name: string, lastName: string, role: Types.UserRoleEnum, credentials?: { __typename?: 'Credential', accessToken: string, client: string, expiry: number, tokenType: string, uid: string } | null } | null } } | null };


export const SignInUserDocument = `
    mutation SignInUser($input: SignInUserInput!) {
  signInUser(input: $input) {
    data {
      id
      role
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
    message
    success
  }
}
    `;
export const SignOutUserDocument = `
    mutation SignOutUser {
  signOutUser {
    data {
      id
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
    message
    success
  }
}
    `;
export const SignUpUserDocument = `
    mutation signUpUser($input: SignUpUserInput!) {
  signUpUser(input: $input) {
    data {
      email
      id
      role
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
    message
    success
  }
}
    `;
export const SignInOrSignUpUserOauthDocument = `
    mutation SignInOrSignUpUserOauth($input: SignInOrSignUpUserOauthInput!) {
  signInOrSignUpUserOauth(input: $input) {
    data {
      id
      role
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
    message
    success
  }
}
    `;
export const CfAuthDocument = `
    mutation CfAuth($cfAuthToken: String!) {
  cfAuth(cfAuthToken: $cfAuthToken) {
    data {
      redirectPath
      user {
        id
        email
        name
        lastName
        role
        credentials {
          accessToken
          client
          expiry
          tokenType
          uid
        }
      }
    }
    message
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    SignInUser: build.mutation<SignInUserMutation, SignInUserMutationVariables>({
      query: (variables) => ({ document: SignInUserDocument, variables })
    }),
    SignOutUser: build.mutation<SignOutUserMutation, SignOutUserMutationVariables | void>({
      query: (variables) => ({ document: SignOutUserDocument, variables })
    }),
    signUpUser: build.mutation<SignUpUserMutation, SignUpUserMutationVariables>({
      query: (variables) => ({ document: SignUpUserDocument, variables })
    }),
    SignInOrSignUpUserOauth: build.mutation<SignInOrSignUpUserOauthMutation, SignInOrSignUpUserOauthMutationVariables>({
      query: (variables) => ({ document: SignInOrSignUpUserOauthDocument, variables })
    }),
    CfAuth: build.mutation<CfAuthMutation, CfAuthMutationVariables>({
      query: (variables) => ({ document: CfAuthDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSignInUserMutation, useSignOutUserMutation, useSignUpUserMutation, useSignInOrSignUpUserOauthMutation, useCfAuthMutation } = injectedRtkApi;

