import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useUserProfileQuery } from "api/owner/user/enhancedOperations";
import useAuth from "hooks/useAuth";

export default function useUserProfile() {
  const { isAuthenticated } = useAuth();
  const { data, refetch } = useUserProfileQuery(
    isAuthenticated ? {} : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  return { data, refetch };
}
