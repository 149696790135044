import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useUserCardsQuery } from "api/cards/operations.generated";
import { UserCardsInput } from "types";
import { ONE_CLICK_PAYMENT_GATEWAY_ID } from "utils";

const oneClickPaymentGateways = [ONE_CLICK_PAYMENT_GATEWAY_ID];

export default function useUserCards(input: UserCardsInput) {
  const shouldSkip =
    oneClickPaymentGateways.includes(input.paymentGatewayId) &&
    input.propertyId;
  const { data, ...rest } = useUserCardsQuery(
    shouldSkip ? { input } : skipToken
  );

  const haveAutomaticPayment = data?.userCards?.some(
    (card) => card.automaticPayment
  );

  const subscribedCard = data?.userCards?.find((card) => card.automaticPayment);

  return {
    cards: data?.userCards,
    ...rest,
    haveAutomaticPayment,
    subscribedCard,
  };
}
