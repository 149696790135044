import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { ArrowIcon } from "@comunidadfeliz/icons";

import { HomeIcon } from "assets";
import { useAuth } from "hooks";
import * as buttonStyles from "styles";

export function GoBackButton() {
  const { t } = useTranslation(["layout"]);
  const { back } = useRouter();

  return (
    <button type="button" css={buttonStyles.goBack} onClick={back}>
      <ArrowIcon />
      {t("layout:goBack")}
    </button>
  );
}

export function GoHomeButton() {
  const { t } = useTranslation(["layout"]);
  const { redirect } = useAuth();

  return (
    <button
      type="button"
      css={buttonStyles.goHome}
      onClick={redirect.properties}
    >
      <HomeIcon />
      {t("layout:goHome")}
    </button>
  );
}

export function SkipButton({ onClick }: { onClick?: () => void }) {
  const { t } = useTranslation(["layout"]);

  return (
    <button type="button" css={buttonStyles.goNext} onClick={onClick}>
      {t("layout:skipStep")}
      <ArrowIcon />
    </button>
  );
}
