import { useTranslation } from "react-i18next";
import { ArrowIcon } from "@comunidadfeliz/icons";

import { useAuth } from "hooks";
import { StepPathEnum } from "utils";
import * as styles from "./index.styles";

export default function GoBackButton() {
  const { t } = useTranslation(["layout"]);
  const { redirect, asPath } = useAuth();

  const backRoutes = {
    [StepPathEnum.STEP_1]: () => redirect.home(),
    [StepPathEnum.STEP_2]: () => redirect.step(1),
    [StepPathEnum.STEP_3]: () => redirect.step(2),
    [StepPathEnum.STEP_4]: () => redirect.step(3),
  }[asPath.split("/")[1]];

  return (
    <button type="button" css={styles.goBack} onClick={backRoutes}>
      <ArrowIcon />
      {t("layout:goBack")}
    </button>
  );
}
