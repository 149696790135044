import SubscribeAutomaticPaymentModal from "features/automaticPayment/SubscribeAutomaticPaymentModal";
import { useAppDispatch, useAppSelector } from "store";
import useUserCards from "api/useUserCards";
import { ONE_CLICK_PAYMENT_GATEWAY_ID } from "utils";
import { Card } from "types";
import UnsubscribeCardModal from "features/form/Cards/UnsubscribeCardModal";
import {
  setSubscribeAutomaticPaymentModal,
  setUnsubscribeCardModal,
} from "./index.slice";

export default function Modals() {
  const {
    modals: { automaticPayment },
    automatic_payment: { propertyId },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const { subscribedCard } = useUserCards({
    paymentGatewayId: ONE_CLICK_PAYMENT_GATEWAY_ID,
    propertyId,
  });

  const modal = {
    subscribeAutomaticPayment: (
      <SubscribeAutomaticPaymentModal
        isOpen={automaticPayment.subscribeAutomaticPayment}
        onOpenChange={() => dispatch(setSubscribeAutomaticPaymentModal(false))}
        subscribedCard={subscribedCard as Card}
        showSelectCardInput
      />
    ),
    unsubscribeCard: (
      <UnsubscribeCardModal
        open={automaticPayment.unsubscribeCard}
        onOpenChange={() => dispatch(setUnsubscribeCardModal(false))}
        cardId={subscribedCard?.id as string}
        propertyId={propertyId}
      />
    ),
  };

  return (
    <>
      {modal.subscribeAutomaticPayment}
      {modal.unsubscribeCard}
    </>
  );
}
