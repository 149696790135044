import { css } from "@emotion/react";
import { theme } from "@comunidadfeliz/theme";
import { TABLET_BREAKPOINT } from "styles";

export const cardsContainer = css`
  background-color: ${theme.colors.white};
  box-shadow: 0px 1px 2px rgba(196, 198, 207, 0.5);
  padding: 16px 16px 4px;
`;

export const modalContent = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${theme.colors.gray.primary};
  & p,
  & li {
    font-size: 12px;
  }
`;

export const registerNewCard = css`
  display: grid;
  grid-template-columns: 320px 224px;
  gap: 16px;
  position: relative;
  & > button {
    height: 48px;
  }
`;

export const cardInfoStyle = css`
  display: flex;
  margin-right: auto;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.gray.primary};
  & > svg {
    width: 32px;
    height: 24px;
  }
`;

export const cardInfoStyleInputValue = css`
  ${cardInfoStyle}
  z-index: 100;
  position: absolute;
  left: 10px;
  top: 12px;
  & > svg {
    width: 32px;
    height: 24px;
  }
`;

export const cardTypeStyle = css`
  font-size: 16px;
  font-weight: bold;
`;

export const cardNumberStyle = css`
  font-size: 14px;
  color: ${theme.colors.gray.primary};
`;

export const selectDay = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const disclaimer = css`
  padding: 10px;
  background-color: ${theme.colors.blue.quinary};
  border-radius: 4px;
  > span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  & > span > p {
    font-weight: 700;
  }
  & > li {
    list-style: disc;
    list-style-position: inside;
  }
`;

export const subscribeButton = css`
  display: flex;
  white-space: nowrap;
  cursor: pointer;

  @media (max-width: ${TABLET_BREAKPOINT}) {
    order: 4;
    width: 100%;
  }
`;
