import { theme } from "@comunidadfeliz/theme";
import { css } from "@emotion/react";

export const table = css`
  max-width: calc(100vw - 40px);
  overflow-x: auto;
  color: ${theme.colors.gray.primary};
  & div > div {
    align-items: center;
    z-index: 0;
    min-width: auto;
    word-break: break-all;
    white-space: nowrap;
  }
  z-index: 0;
  & > div > div {
    z-index: 1;
  }
`;

export const emptyRow = css`
  width: 100%;
  text-align: center;
  padding: 20px;
  grid-column-start: 1;
  grid-column-end: 9;
`;
