import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { BCI_PIXEL } from "lib/bciPixel";
import { BCI_SOURCES } from "utils";
import { setLoadBCIScript } from "./index.slice";

export default function useBciScriptLoader() {
  const { loadBCIScript } = useAppSelector((state) => state.bci);
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
  } = router.query;

  const haveBCIParams =
    utmSource === BCI_SOURCES.SOURCE &&
    utmMedium === BCI_SOURCES.MEDIUM &&
    utmCampaign === BCI_SOURCES.CAMPAIGN;

  useEffect(() => {
    if (haveBCIParams) {
      dispatch(setLoadBCIScript(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  useEffect(() => {
    if (loadBCIScript) {
      setBCIScript();
    }

    return () => {
      document.querySelectorAll("script").forEach((script) => {
        if (script.src.includes(BCI_PIXEL)) {
          document.body.removeChild(script);
        }
      });
    };
  }, [loadBCIScript]);

  const setBCIScript = () => {
    const script = document.createElement("script");
    script.src = BCI_PIXEL;
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);
  };

  const setBCIonLocalStorage = () => {
    if (loadBCIScript) {
      localStorage.setItem("bci", "true");
    }
  };

  const getBCIFromLocalStorage = localStorage.getItem("bci");

  const removeBCIFromLocalStorage = () => {
    localStorage.removeItem("bci");
  };

  useEffect(() => {
    const handleUnload = () => {
      if (!router.asPath.includes("step-4")) {
        removeBCIFromLocalStorage();
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [router.asPath]);

  return {
    setBCIScript,
    loadBCIScript,
    setBCIonLocalStorage,
    getBCIFromLocalStorage,
    removeBCIFromLocalStorage,
  };
}
