import * as Types from '../../types/schema';

import { api } from 'store/baseApi';

/* eslint-disable */
/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit one of the `.graphql` files in this project and run
 * yarn codegen
 * for this file to be re-created
 * */

export type UpdateUserRoleMutationVariables = Types.Exact<{
  role: Types.UserRoleEnum;
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', updateUserRole?: { __typename?: 'UpdateUserRolePayload', message: string, success: boolean, data: { __typename?: 'User', id: string, name: string, email: string } } | null };


export const UpdateUserRoleDocument = `
    mutation UpdateUserRole($role: UserRoleEnum!) {
  updateUserRole(role: $role) {
    data {
      id
      name
      email
    }
    message
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateUserRole: build.mutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>({
      query: (variables) => ({ document: UpdateUserRoleDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateUserRoleMutation } = injectedRtkApi;

