import * as ConfirmationModal from "@comunidadfeliz/confirmation-modal";
import Button from "@comunidadfeliz/button";

import { ConfirmationModalProps } from "types";
import * as buttonStyles from "styles/button";

import { useTranslation } from "react-i18next";

export default function ExpiryModal({
  open,
  onOpenChange,
}: ConfirmationModalProps) {
  const { t } = useTranslation(["common"]);

  return (
    <ConfirmationModal.Root open={open} onOpenChange={onOpenChange}>
      <ConfirmationModal.Content>
        <ConfirmationModal.Title>
          {t("common:sessionExpiredModal.title")}
        </ConfirmationModal.Title>
        <ConfirmationModal.Description>
          {t("common:sessionExpiredModal.description")}
        </ConfirmationModal.Description>
        <ConfirmationModal.Actions>
          <Button css={buttonStyles.button} onClick={() => onOpenChange(false)}>
            <p>{t("common:sessionExpiredModal.button")}</p>
          </Button>
        </ConfirmationModal.Actions>
      </ConfirmationModal.Content>
    </ConfirmationModal.Root>
  );
}
