import { css } from "@emotion/react";

export const checkboxContainer = css`
  padding: 0 10px;
  & button[data-state="checked"] {
    background-color: var(--color-checkbox) !important;
  }
  & button:hover::before {
    background-color: var(--color-checkbox-unchecked) !important;
    opacity: 0.6 !important;
  }
`;
