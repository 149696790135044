import { NavbarLogo } from "assets";
import { useAuth } from "hooks";
import useUtmLog from "hooks/useUtmLog";
import * as styles from "./index.styles";
import AccountMenu from "./AccountMenu";

export default function Navbar() {
  const { isAuthenticated, redirect, isOwner, payerRedirect } = useAuth();
  const { storeUtm } = useUtmLog();

  const handleLandingRedirect = () => {
    storeUtm();
    redirect.afLanding();
  };

  const redirectProperties = isOwner
    ? redirect.properties
    : payerRedirect.properties;

  const logoLink = isAuthenticated ? redirectProperties : handleLandingRedirect;

  return (
    <nav css={styles.navbar}>
      <NavbarLogo css={styles.logo} onClick={logoLink} />
      <AccountMenu />
    </nav>
  );
}
