import { css } from "@emotion/react";
import { theme } from "@comunidadfeliz/theme";

export const filtersContainer = css`
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

export const dateFilter = css`
  display: flex;
  gap: 3px;
  align-items: center;
`;

export const filterInput = css`
  display: flex;
  border-radius: 5px;
  border: 1px solid ${theme.colors.gray.quaternary};
  height: 30px;
  padding: 3px 10px;
  width: 100%;
`;

export const dispersionDate = css`
  border-radius: 5px;
  border: 1px solid ${theme.colors.gray.quaternary};
  height: 30px;
  padding: 3px 10px;
  margin-right: 10px;
`;

export const filterButton = css`
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  flex-shrink: 0;
  & svg {
    width: 50%;
    height: 50%;
  }
  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.blue.quaternary};
  }
`;

export const selectFilter = css`
  & option[disabled] {
    display: none;
  }
  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const selectOption = css`
  padding: 10px;
  max-width: 100%;
`;

export const radioTitle = css`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
  color: ${theme.colors.gray.primary};
`;

export const radio = css`
  & button {
    &:hover {
      background-color: var(--color-checkbox-unchecked);
      box-shadow: 0 0 0 10px var(--color-checkbox-unchecked);
    }
    &[data-state="checked"] {
      border-color: var(--color-checkbox);
      &:hover {
        border-color: ${theme.colors.gray.secondary};
      }
    }
  }
  & span {
    background-color: var(--color-checkbox);
  }
`;

export const cleanButton = css`
  cursor: pointer;
  &:hover {
    color: ${theme.colors.blue.primary};
  }
`;

export const selectedItems = css`
  white-space: nowrap;
`;
