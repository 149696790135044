import { api as generatedApi } from "./operations.generated";

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ["Cards"],
  endpoints: {
    CreateAutomaticPaymentReminder: {
      invalidatesTags: ["Cards"],
    },
    EnrollCardLink: { providesTags: ["Cards"] },
    UserCards: { providesTags: ["Cards"] },
    DeleteUserCard: {
      invalidatesTags: ["Cards"],
    },
    CreateCard: {
      invalidatesTags: ["Cards"],
    },
    CalculateAutomaticPaymentDates: {
      providesTags: ["Cards"],
    },
    DeleteAutomaticPaymentReminder: {
      invalidatesTags: ["Cards"],
    },
  },
});

export const {
  useDeleteUserCardMutation,
  useCreateAutomaticPaymentReminderMutation,
  useCreateCardMutation,
  useDeleteAutomaticPaymentReminderMutation,
} = api;
