import { theme } from "@comunidadfeliz/theme";
import { css } from "@emotion/react";

export const baseCard = css`
  border-radius: 8px;
  background-color: ${theme.colors.white};
`;

export const alertCard = css`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: ${theme.colors.blue.quinary};
  margin: auto;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.3px;
`;

export const advertisement = css`
  background: #e5e6e9;
  height: 100%;
  display: flex;
  gap: 12px;
  padding: 12px;
  margin-left: auto;
  max-width: 300px;
  & p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  align-items: center;
  & svg {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

export const advertisementBlue = css`
  background: ${theme.colors.blue.quinary};
  color: ${theme.colors.gray.secondary};
  height: 100%;
  display: flex;
  gap: 12px;
  padding: 4px;
  margin-left: auto;
  & p {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.4px;
  }
  align-items: center;
  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;
