import * as Types from '../../types/schema';

import { api } from 'store/baseApi';

/* eslint-disable */
/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit one of the `.graphql` files in this project and run
 * yarn codegen
 * for this file to be re-created
 * */

export type UserCardsQueryVariables = Types.Exact<{
  input: Types.UserCardsInput;
}>;


export type UserCardsQuery = { __typename?: 'Query', userCards?: Array<{ __typename?: 'Card', automaticPayment: boolean, id: string, cardType: Types.UserCardTypeEnum, cardNumber: string, metadata: any }> | null };

export type EnrollCardLinkQueryVariables = Types.Exact<{
  input: Types.EnrollCardInput;
}>;


export type EnrollCardLinkQuery = { __typename?: 'Query', enrollCardLink: string };

export type DeleteUserCardMutationVariables = Types.Exact<{
  cardId: Types.Scalars['ID'];
}>;


export type DeleteUserCardMutation = { __typename?: 'Mutation', deleteUserCard: { __typename?: 'DeleteUserCardPayload', message: string, success: boolean } };

export type SubmitPaymentOneclickMutationVariables = Types.Exact<{
  input: Types.SubmitPaymentInput;
}>;


export type SubmitPaymentOneclickMutation = { __typename?: 'Mutation', submitPaymentOneclick?: { __typename?: 'SubmitPaymentOneclickPayload', message: string, success: boolean, data: { __typename?: 'SubmitPayment', purchaseOrderId: number, redirectUrl: string, status: string, totalAmount: number, totalAmountStr: string } } | null };

export type CreateCardMutationVariables = Types.Exact<{
  paymentGatewayId: Types.Scalars['ID'];
}>;


export type CreateCardMutation = { __typename?: 'Mutation', createCard?: { __typename?: 'CreateCardPayload', message: string, success: boolean, data: { __typename?: 'AfCard', cardType: string, lastDigits: string, externalId: string, lastPaymentId?: string | null, paymentGatewayId: string } } | null };

export type CreateAutomaticPaymentReminderMutationVariables = Types.Exact<{
  input: Types.CreateAutomaticPaymentReminderInput;
}>;


export type CreateAutomaticPaymentReminderMutation = { __typename?: 'Mutation', createAutomaticPaymentReminder?: { __typename?: 'CreateAutomaticPaymentReminderPayload', message: string, success: boolean, data: { __typename?: 'AutomaticPaymentReminder', active: boolean, cardId: string, dayNumber: number, id: string, payerChargeDate: any, propertyId: string, status: Types.AutomaticPaymentReminderStatusEnum, userId: string } } | null };

export type CalculateAutomaticPaymentDatesQueryVariables = Types.Exact<{
  paymentDayIndex: Types.Scalars['Int'];
}>;


export type CalculateAutomaticPaymentDatesQuery = { __typename?: 'Query', calculateAutomaticPaymentDates: { __typename?: 'AutomaticPaymentDates', firstChargeDate: any, firstPayDate: any, nextChargeDate: any, nextPayDate: any } };

export type DeleteAutomaticPaymentReminderMutationVariables = Types.Exact<{
  input: Types.DeleteAutomaticPaymentReminderInput;
}>;


export type DeleteAutomaticPaymentReminderMutation = { __typename?: 'Mutation', deleteAutomaticPaymentReminder?: { __typename?: 'DeleteAutomaticPaymentReminderPayload', message: string, success: boolean } | null };


export const UserCardsDocument = `
    query UserCards($input: UserCardsInput!) {
  userCards(input: $input) {
    automaticPayment
    id
    cardType
    cardNumber
    metadata
  }
}
    `;
export const EnrollCardLinkDocument = `
    query EnrollCardLink($input: EnrollCardInput!) {
  enrollCardLink(input: $input)
}
    `;
export const DeleteUserCardDocument = `
    mutation DeleteUserCard($cardId: ID!) {
  deleteUserCard(cardId: $cardId) {
    message
    success
  }
}
    `;
export const SubmitPaymentOneclickDocument = `
    mutation SubmitPaymentOneclick($input: SubmitPaymentInput!) {
  submitPaymentOneclick(input: $input) {
    data {
      purchaseOrderId
      redirectUrl
      status
      totalAmount
      totalAmountStr
    }
    message
    success
  }
}
    `;
export const CreateCardDocument = `
    mutation CreateCard($paymentGatewayId: ID!) {
  createCard(paymentGatewayId: $paymentGatewayId) {
    data {
      cardType
      lastDigits
      externalId
      lastPaymentId
      paymentGatewayId
    }
    message
    success
  }
}
    `;
export const CreateAutomaticPaymentReminderDocument = `
    mutation CreateAutomaticPaymentReminder($input: CreateAutomaticPaymentReminderInput!) {
  createAutomaticPaymentReminder(input: $input) {
    data {
      active
      cardId
      dayNumber
      id
      payerChargeDate
      propertyId
      status
      userId
    }
    message
    success
  }
}
    `;
export const CalculateAutomaticPaymentDatesDocument = `
    query CalculateAutomaticPaymentDates($paymentDayIndex: Int!) {
  calculateAutomaticPaymentDates(paymentDayIndex: $paymentDayIndex) {
    firstChargeDate
    firstPayDate
    nextChargeDate
    nextPayDate
  }
}
    `;
export const DeleteAutomaticPaymentReminderDocument = `
    mutation DeleteAutomaticPaymentReminder($input: DeleteAutomaticPaymentReminderInput!) {
  deleteAutomaticPaymentReminder(input: $input) {
    message
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UserCards: build.query<UserCardsQuery, UserCardsQueryVariables>({
      query: (variables) => ({ document: UserCardsDocument, variables })
    }),
    EnrollCardLink: build.query<EnrollCardLinkQuery, EnrollCardLinkQueryVariables>({
      query: (variables) => ({ document: EnrollCardLinkDocument, variables })
    }),
    DeleteUserCard: build.mutation<DeleteUserCardMutation, DeleteUserCardMutationVariables>({
      query: (variables) => ({ document: DeleteUserCardDocument, variables })
    }),
    SubmitPaymentOneclick: build.mutation<SubmitPaymentOneclickMutation, SubmitPaymentOneclickMutationVariables>({
      query: (variables) => ({ document: SubmitPaymentOneclickDocument, variables })
    }),
    CreateCard: build.mutation<CreateCardMutation, CreateCardMutationVariables>({
      query: (variables) => ({ document: CreateCardDocument, variables })
    }),
    CreateAutomaticPaymentReminder: build.mutation<CreateAutomaticPaymentReminderMutation, CreateAutomaticPaymentReminderMutationVariables>({
      query: (variables) => ({ document: CreateAutomaticPaymentReminderDocument, variables })
    }),
    CalculateAutomaticPaymentDates: build.query<CalculateAutomaticPaymentDatesQuery, CalculateAutomaticPaymentDatesQueryVariables>({
      query: (variables) => ({ document: CalculateAutomaticPaymentDatesDocument, variables })
    }),
    DeleteAutomaticPaymentReminder: build.mutation<DeleteAutomaticPaymentReminderMutation, DeleteAutomaticPaymentReminderMutationVariables>({
      query: (variables) => ({ document: DeleteAutomaticPaymentReminderDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUserCardsQuery, useLazyUserCardsQuery, useEnrollCardLinkQuery, useLazyEnrollCardLinkQuery, useDeleteUserCardMutation, useSubmitPaymentOneclickMutation, useCreateCardMutation, useCreateAutomaticPaymentReminderMutation, useCalculateAutomaticPaymentDatesQuery, useLazyCalculateAutomaticPaymentDatesQuery, useDeleteAutomaticPaymentReminderMutation } = injectedRtkApi;

