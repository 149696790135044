import { useEffect } from "react";
import { useRouter } from "next/router";

import { setRole } from "features/owner/index.slice";
import { useAppDispatch, useAppSelector } from "store";
import { UserRoleEnum } from "types";
import { useUpdateUserRoleMutation } from "api/user/operations.generated";
import useAuth from "./useAuth";

export default function useRole() {
  const dispatch = useAppDispatch();
  const { payerRedirect, redirect } = useAuth();
  const { role } = useAppSelector((state) => state.owner_auth);
  const { asPath } = useRouter();

  const changeRole = (r: UserRoleEnum) => dispatch(setRole(r));
  const setOwner = () => dispatch(setRole(UserRoleEnum.Payee));
  const setPayer = () => dispatch(setRole(UserRoleEnum.Payer));
  const [updateUserRole] = useUpdateUserRoleMutation();

  const isRole = {
    owner: role === UserRoleEnum.Payee,
    payer: role === UserRoleEnum.Payer,
  };

  useEffect(() => {
    if (asPath.includes("propietario")) {
      setOwner();
    } else if (asPath.includes("pagador")) {
      setPayer();
    } else if (!role) {
      setPayer();
    }
  }, [asPath]);

  const userRedirect = {
    owner: () => {
      updateUserRole({ role: UserRoleEnum.PayerPayee });
      setOwner();
      redirect.properties();
    },
    payer: () => {
      updateUserRole({ role: UserRoleEnum.PayerPayee });
      setPayer();
      payerRedirect.properties();
    },
  };

  return {
    changeRole,
    setOwner,
    setPayer,
    userRedirect,
    role,
    isRole,
  };
}
