import * as Types from '../../../types/schema';

import { api } from 'store/baseApi';

/* eslint-disable */
/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit one of the `.graphql` files in this project and run
 * yarn codegen
 * for this file to be re-created
 * */

export type UserProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: 'Query', userProfile?: { __typename?: 'User', email: string, id: string, lastName: string, name: string, phone: string, tin: string, role: Types.UserRoleEnum, payee?: { __typename?: 'Payee', id: string } | null } | null };

export type UserBankDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserBankDataQuery = { __typename?: 'Query', userProfile?: { __typename?: 'User', payee?: { __typename?: 'Payee', bankStr: string, bankAccountType?: Types.BankAccountTypeEnum | null, bankAccountNumberLastDigits: string } | null } | null };

export type UserBankDataFormQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserBankDataFormQuery = { __typename?: 'Query', userProfile?: { __typename?: 'User', payee?: { __typename?: 'Payee', id: string, tin: string, bank: Types.BankEnum, bankAccountType?: Types.BankAccountTypeEnum | null, bankAccountNumber?: string | null } | null } | null };

export type UpdateUserPayeeMutationVariables = Types.Exact<{
  input: Types.UpdateUserPayeeInput;
}>;


export type UpdateUserPayeeMutation = { __typename?: 'Mutation', updateUserPayee?: { __typename?: 'UpdateUserPayeePayload', message: string, success: boolean, data: { __typename?: 'Payee', id: string } } | null };

export type UpdateProfileMutationVariables = Types.Exact<{
  input: Types.UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile?: { __typename?: 'UpdateProfilePayload', message: string, success: boolean, data: { __typename?: 'User', id: string } } | null };


export const UserProfileDocument = `
    query UserProfile {
  userProfile {
    email
    id
    lastName
    name
    phone
    tin
    role
    payee {
      id
    }
  }
}
    `;
export const UserBankDataDocument = `
    query UserBankData {
  userProfile {
    payee {
      bankStr
      bankAccountType
      bankAccountNumberLastDigits
    }
  }
}
    `;
export const UserBankDataFormDocument = `
    query UserBankDataForm {
  userProfile {
    payee {
      id
      tin
      bank
      bankAccountType
      bankAccountNumber
    }
  }
}
    `;
export const UpdateUserPayeeDocument = `
    mutation UpdateUserPayee($input: UpdateUserPayeeInput!) {
  updateUserPayee(input: $input) {
    data {
      id
    }
    message
    success
  }
}
    `;
export const UpdateProfileDocument = `
    mutation UpdateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    data {
      id
    }
    message
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UserProfile: build.query<UserProfileQuery, UserProfileQueryVariables | void>({
      query: (variables) => ({ document: UserProfileDocument, variables })
    }),
    UserBankData: build.query<UserBankDataQuery, UserBankDataQueryVariables | void>({
      query: (variables) => ({ document: UserBankDataDocument, variables })
    }),
    UserBankDataForm: build.query<UserBankDataFormQuery, UserBankDataFormQueryVariables | void>({
      query: (variables) => ({ document: UserBankDataFormDocument, variables })
    }),
    UpdateUserPayee: build.mutation<UpdateUserPayeeMutation, UpdateUserPayeeMutationVariables>({
      query: (variables) => ({ document: UpdateUserPayeeDocument, variables })
    }),
    UpdateProfile: build.mutation<UpdateProfileMutation, UpdateProfileMutationVariables>({
      query: (variables) => ({ document: UpdateProfileDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUserProfileQuery, useLazyUserProfileQuery, useUserBankDataQuery, useLazyUserBankDataQuery, useUserBankDataFormQuery, useLazyUserBankDataFormQuery, useUpdateUserPayeeMutation, useUpdateProfileMutation } = injectedRtkApi;

