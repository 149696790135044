/* eslint-disable no-nested-ternary */
import { EyeIcon, HideEyeIcon } from "@comunidadfeliz/icons";
import * as styles from "../index.styles";

type PasswordButtonProps = {
  type?: HTMLInputElement["type"];
  setType: (type: HTMLInputElement["type"]) => void;
};

export default function PasswordButton({ type, setType }: PasswordButtonProps) {
  const handleShowPassword = () => {
    setType("name");
  };

  const handleHidePassword = () => {
    setType("password");
  };

  return type === "password" ? (
    <HideEyeIcon onClick={handleShowPassword} css={styles.eyeIcon} />
  ) : (
    <EyeIcon onClick={handleHidePassword} css={styles.eyeIcon} />
  );
}
