import { css } from "@emotion/react";
import { theme } from "@comunidadfeliz/theme";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "styles";

export const formContainer = css`
  border-radius: 4px;
  width: 100%;
  background: ${theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(196, 198, 207, 0.5);
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;
  gap: 32px;
  margin-bottom: 20px;
  & > h1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }
  & > h2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.25px;
  }
  & p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.25px;
  }
  & > * {
    width: 100%;
  }
`;

export const loginFormContainer = css`
  ${formContainer}
  max-width: 530px;
`;

export const loginFooter = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  text-align: center;
  & > button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  & > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
  & > span > button {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: var(--color-links);
    cursor: pointer;
  }
`;

export const inputContainer = css`
  display: flex;
`;

export const eyeIcon = css`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  z-index: 1;
  top: 12px;
  background-color: ${theme.colors.white};
`;

export const faq = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${TABLET_BREAKPOINT}) {
    color: ${theme.colors.white};
  }
`;

export const signUpButtons = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  background: ${theme.colors.white};
  max-width: 530px;
  padding: 20px 20px;
  & > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: center;
    margin-inline: auto;
  }
`;

export const buttonContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  background: ${theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(196, 198, 207, 0.5);
  max-width: 530px;
  padding: 20px 20px;
  & > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.25px;
  }
`;

export const stepperContainer = (step: number) => css`
  & > div > div > span {
    width: 250px;
    margin-left: -122px;
  }
  & div > hr {
    width: 237px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    & > div > div > span {
      width: 150px;
      margin-left: -72px;
    }
    & div > hr {
      width: 30vw;
    }
  }
  & path {
    fill: var(--color-stepper) !important;
  }
  & span {
    color: var(--color-stepper) !important;
  }
  & div:nth-of-type(n + ${step + 1}) > span {
    color: #828599 !important;
  }
  & hr:nth-of-type(-n + ${step - 1}) {
    border-color: var(--color-stepper) !important;
  }
  & div:nth-of-type(${step}) > div {
    border-color: var(--color-stepper) !important;
    color: var(--color-stepper) !important;
  }
`;

export const checkboxContainer = css`
  padding: 0 10px;
  & button[data-state="checked"] {
    background-color: var(--color-checkbox) !important;
  }
  & button:hover::before {
    background-color: var(--color-checkbox-unchecked) !important;
    opacity: 0.6 !important;
  }
`;

export const radioContainer = css`
  display: flex;
  flex-direction: column;
  gap: 14px;
  & > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.25px;
  }
`;
