import { ChangeEvent, useState } from "react";
import { SignInUserInput } from "types";
import { useTranslation } from "react-i18next";
import Button from "@comunidadfeliz/button";
import { isValidEmail } from "utils";
import * as buttonStyles from "styles";
import { useAuth } from "hooks";
import HelpButton from "features/form/components/HelpButton";
import {
  ONBOARDING_PROPERTY_FORM_NAME,
  ONBOARDING_FORM_NAME,
} from "store/constants";
import { useOwnerLogin } from "./useLogin";
import Input from "../components/Input";
import * as styles from "../index.styles";
import GoogleLogin from "../alternativeLogin/GoogleLogin";
import CfLogin from "../alternativeLogin/CfLogin";

export default function LoginForm() {
  const { t } = useTranslation(["layout", "owner"]);
  const { signInUser } = useOwnerLogin();
  const { redirectToSignUp, redirect } = useAuth();

  const [input, setInput] = useState<SignInUserInput>({
    email: "",
    password: "",
  });

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [target.name]: target.value });
  };

  const handleLogin = () => {
    signInUser({ input });
    localStorage.removeItem(ONBOARDING_PROPERTY_FORM_NAME);
    localStorage.removeItem(ONBOARDING_FORM_NAME);
  };

  return (
    <>
      <div css={styles.loginFormContainer}>
        <h1>{t("owner:login.title")}</h1>
        <Input
          type="email"
          name="email"
          value={input.email}
          onChange={handleChange}
          validator={isValidEmail}
        />
        <Input
          type="password"
          name="password"
          value={input.password}
          onChange={handleChange}
        />
        <Button
          onClick={handleLogin}
          css={buttonStyles.button}
          disabled={!input.email || !input.password}
        >
          {t("owner:login.login")}
        </Button>
        <div css={styles.loginFooter}>
          <Button
            css={buttonStyles.linkButton}
            variant="link"
            onClick={redirect.recoveryPassword}
          >
            {t("owner:login.forgotPassword")}
          </Button>
          <p>{t("owner:login.noAccount")}</p>
          <span>
            <button type="button" onClick={redirectToSignUp}>
              {t("owner:login.signUpHere")}
            </button>
          </span>
        </div>
      </div>
      <div css={styles.buttonContainer}>
        <p>{t("owner:commons.enterToAccount")}</p>
        <GoogleLogin />
        <CfLogin />
      </div>
      <div css={styles.faq}>
        <p>{t("layout:needHelp")} </p>
        <HelpButton />
      </div>
    </>
  );
}
